// import set_modal from "modal";
$(document).on("turbolinks:load", function () {
  // 金額単位変更用
  set_modal("unit", ".unit-modal");

  // 財務状況のフォームの値が変化した場合はプレビューリンクを非アクティブにする
  $("form").on("change", function () {
    $(".preview-link__link").addClass("preview-link__link--disabled");
  });

  // 事業概要の固定資産の入力用
  active_text_area(
    "#business_summary_facility_flag_true",
    "#business_summary_facility_flag_false",
    "#business_summary_facility_valuation"
  );
  active_text_area(
    "#business_summary_real_estate_flag_true",
    "#business_summary_real_estate_flag_false",
    "#business_summary_real_estate_valuation"
  );
  // 希望条件の譲渡希望価額の入力用
  active_text_area(
    "#desired_condition_desired_amount_flag_true",
    "#desired_condition_desired_amount_flag_false",
    "#desired_condition_desired_amount"
  );
  // 希望条件のM&A仲介手数料の入力用
  active_text_area(
    "#desired_condition_agent_fee_flag_true",
    "#desired_condition_agent_fee_flag_false",
    "#desired_condition_agent_fee"
  );

  // 譲渡理由のその他を選択した場合
  $("#desired_condition_reason_other").click(function () {
    if ($("#desired_condition_reason_other_text").attr("disabled")) {
      $("#desired_condition_reason_other_text").attr("disabled", false);
      $("#desired_condition_reason_other_text").removeClass("is-disabled");
    } else {
      $("#desired_condition_reason_other_text").attr("disabled", true);
      $("#desired_condition_reason_other_text").addClass("is-disabled");
      $("#desired_condition_reason_other_text").val("");
    }
  });

  // input[type="number"]にeを入力できないようにする
  $("input[type='number']").on("keydown", function (e) {
    return e.keyCode !== 69;
  });
});

function set_modal(modal_name, modal_class) {
  // モーダルを開く
  $(`.${modal_name}-modal-open`).on("click", function () {
    const unit = $(this).data("unit");
    $(".unit-modal h2").html(change_unit_text(unit));
    $(modal_class).fadeIn();
    return false;
  });
  // モーダルを閉じる
  $(`.${modal_name}-modal-close`).on("click", function () {
    $(modal_class).fadeOut();
    return false;
  });
}

// 単位変更モーダルの文言
function change_unit_text(unit) {
  switch (unit) {
    case "million":
      return `単位を千円から百万円に切り替えますか？単位を切り替えると入力した数値は全てクリアされます。`;
    case "thousand":
      return `単位を百万円から千円に切り替えますか？単位を切り替えると入力した数値は全てクリアされます。`;
    default:
      break;
  }
}

// 固定資産入力用
function active_text_area(radio_true, radio_false, text_area_id) {
  if ($(radio_true).prop("checked")) {
    $(text_area_id).attr("disabled", false);
    $(text_area_id).removeClass("is-disabled");
  }
  $(radio_true).click(function () {
    $(text_area_id).attr("disabled", false);
    $(text_area_id).removeClass("is-disabled");
  });
  $(radio_false).click(function () {
    $(text_area_id).attr("disabled", true);
    $(text_area_id).addClass("is-disabled");
    $(text_area_id).val("");
  });
}
