$(document).on("turbolinks:load", function () {
  $(function () {
    // 招待メール送信 /////////////////////////////////////////////////////////////////////////////////////////
    // モーダルを開く
    $("#proxy_registartion_modal input").keypress(function (e) {
      // エンターを押下した場合はモーダル表示
      if (e.keyCode == 13) {
        $(".email_text").text($(".con_mail").val());
        $(".send_mail_confirm").fadeIn();
        return false;
      }
    });
    $("#send_mail_confirm_modal_open").on("click", function () {
      $(".email_text").text($(".con_mail").val());
      $(".send_mail_confirm").fadeIn();
      return false;
    });
    // モーダルを閉じる
    $("#send_mail_confirm_modal_close").on("click", function () {
      $(".send_mail_confirm").fadeOut();
      return false;
    });

    // 招待メール再送信 ///////////////////////////////////////////////////////////////////////////////////////
    // モーダルを開く
    $(".re_send_mail_confirm_modal_open").on("click", function () {
      const re_send_email = $(this).closest("li").find(".invitation-list__mail").text();
      $(".re_email_text").text(re_send_email);
      $(".re_send_mail_confirm input[type='hidden']").val(re_send_email);
      $(".re_send_mail_confirm").fadeIn();
      return false;
    });

    // モーダルを閉じる
    $("#re_send_mail_confirm_modal_close").on("click", function () {
      $(".re_send_mail_confirm").fadeOut();
      return false;
    });

    // 削除 /////////////////////////////////////////////////////////////////////////////////////////////////
    // モーダルを開く
    $(".delete_waiting_user_modal_open").on("click", function () {
      $(".delete_waiting_user").fadeIn();
      return false;
    });
    // モーダルを閉じる
    $("#delete_waiting_user_modal_close").on("click", function () {
      $(".delete_waiting_user").fadeOut();
      return false;
    });
  });
});
