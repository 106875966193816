$(document).on("turbolinks:load", function () {
  // 「その他」チェックボックスを押下時に入力エリアを表示/非表示
  $("#service_suspension_request_reason_other").on("click", function () {
    if (this.checked) {
      $(".reason_other_text_area").show();
    } else {
      $(".reason_other_text_area").hide();
      $(".input-textarea").val("");
    }
  });

  // 対象会社（売手）- サービス利用停止 - 申請確認
  //「承諾する」ラジオボタンを押下時に入力エリアを非アクティブ
  $("#service_suspension_request_status_consent").on("click", function () {
    $(".input-textarea").prop("disabled", true);
    $(".denial_reason_area").hide();
  });
  //「お断りする」ラジオボタンを押下時に入力エリアをアクティブ
  $("#service_suspension_request_status_refusal").on("click", function () {
    $(".denial_reason_area").show();
    $(".input-textarea").prop("disabled", false);
  });
  //「承諾する」か「お断りする」ラジオボタンを押下時に入力エリアを表示/ボタンをアクティブ
  $('input[name="service_suspension_request[status]"]').on("click", function () {
    $(".service_suspension_request_button").prop("disabled", false);
  });
});
