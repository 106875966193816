///////////////////////////////////////////////////////////////////////////////////////////////////////////
/////// アコーディオン //////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////
// アコーディオンを閉じる（共通処理）
function close_search_window(title_element) {
  if (title_element.parent().hasClass("is-opened")) {
    title_element.next().slideUp();
    title_element.next().addClass("is-closed");
    title_element.parent().removeClass("is-opened");
  }
}

// 譲渡案件一覧画面における検索窓のアコーディオン表示
$(document).on("turbolinks:load", function () {
  // 通常のアコーディオン処理
  $(".search__title").click(function () {
    if ($(this).parent().hasClass("is-opened")) {
      close_search_window($(this));
    } else {
      $(this).next().slideDown();
      $(this).next().removeClass("is-closed");
      $(this).parent().addClass("is-opened");
    }
  });

  // 検索ボタン、ページネーションを押した時に閉じる処理
  $(".search .button-box button.button-primary, div.pagination").click(function () {
    close_search_window($(".search__title"));
  });

  // ソートした時に閉じる処理
  $(".result-control__action").change(function () {
    close_search_window($(".search__title"));
  });
});

// sp版ノンネームシートの事業概要の開閉用
$(document).on("turbolinks:load", function () {
  $(".detail-table__title--sp-toggle").on("click", function () {
    $(this).parent().toggleClass("is-opened");
    $(this).next().slideToggle();
  });
});

///////////////////////////////////////////////////////////////////////////////////////////////////////////
/////// 業種選択・所在地選択（モーダル） ///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////

// まずは関数（共通処理）をセット

// モーダル内の全選択・全解除（共通処理）
function check_all(input_name, class_name) {
  $(class_name).off("click"); // これがないと複数回処理が走ってしまう
  $(class_name).on("click", function () {
    let checkAll = $(this).find(`input[name="${input_name}_checkall"]`);
    let checkBox = $(this).find(`input[name="${input_name}"]`);
    // 全選択・全解除
    $(checkAll).off("click"); // これがないと複数回処理が走ってしまう
    $(checkAll).on("click", function () {
      $(checkBox).prop("checked", $(checkAll).is(":checked"));
    });
    // 最後のチェックと「全選択」の連動
    $(checkBox).off("click"); // これがないと複数回処理が走ってしまう
    $(checkBox).on("click", function () {
      let boxCount = $(checkBox).length;
      // var checked  = $( checkBox + ':checked' ).length; //動かない
      let checked = 0;
      for (let i = 0; i < boxCount; i++) {
        if ($(checkBox)[i]["checked"]) {
          checked += 1;
        }
      }
      if (checked === boxCount) {
        $(checkAll).prop("checked", true);
      } else {
        $(checkAll).prop("checked", false);
      }
    });
  });
}

//選択を全てクリアする（共通処理）
function clear_all_checks(input_name) {
  $(`input[name="${input_name}_checkall"]:checked`).prop("checked", false);
  $(`input[name="${input_name}"]:checked`).prop("checked", false);
}

//モーダルの選択を反映させる（共通処理）
function input_selected_checks(input_name, search_column) {
  // モーダルで選択した項目を取得
  const input_values = document.querySelectorAll(`input[name=${input_name}]:checked`);
  // 表示場所を確保（青いゾーンがなければ作り、中身は消しておく）
  const blue_zone_class = `td.search-option-table__data.${input_name}`;
  if (!$(blue_zone_class).children().hasClass("search-option-area")) {
    $(blue_zone_class).prepend(
      `<div class="search-option-area"><ul class="search-option-area__list ${input_name}"></ul></div>`
    );
  }
  const selected_options_class = `ul.search-option-area__list.${input_name}`;
  $(selected_options_class).children().remove();
  // モーダルで選択した項目を表示
  if (0 < input_values.length) {
    for (var data of input_values) {
      var data_id = data.value.split(",")[0];
      var data_name = data.value.split(",")[1];
      $(selected_options_class).append(
        `<li class="search-option-area__item"><a class="search-option-area__link">
        <input type="checkbox" checked="true" value="${data_id}"
        name="q[${search_column}][]"
        id="q_${search_column}_${data_id}">
        ${data_name}</a></li>`
      );
    }
    $(selected_options_class).append(
      `<li class="search-option-area__item"><a class="search-option-area__clear">クリア</a></li>`
    );
  } else {
    $(`${blue_zone_class} .search-option-area`).remove();
  }
  // モーダルを閉じる
  $(`.select-${input_name}`).fadeOut();
  // 検索ヒット数を表示するイベントを発火
  $(blue_zone_class).change();
}

// 実行ブロック
$(document).on("turbolinks:load", function () {
  // モーダル表示はmodal.jsに記載

  //モーダル内で項目の切り替え（業種のみ）
  $(".search-industry__title").click(function () {
    if (!$(this).hasClass("is-active")) {
      $(".search-industry__item").removeClass("is-active");
      $(".search-industry__panel").hide();
      $(this).parent().addClass("is-active");
      $(this).next().show();
    }
  });

  // モーダル内の全選択・全解除
  check_all("industry", ".search-industry__panel");
  check_all("area", ".search-area__item");

  //モーダルで選択をクリアする
  $(`#area-modal button.button-back#clear-industry`).click(function () {
    clear_all_checks("industry");
  });
  $(`#area-modal button.button-back#clear-area`).click(function () {
    clear_all_checks("area");
  });

  //モーダルの選択を反映させる
  $(`#area-modal button.button-primary#select-industry`).click(function () {
    input_selected_checks("industry", "industries");
  });
  $(`#area-modal button.button-primary#select-area`).click(function () {
    input_selected_checks("area", "prefectures");
  });

  // 選択済項目を消去(業種・所在地 共通)
  $(document).on(
    "click",
    "#ma_accounting_firm_form .search-option-area__item, #ma_project_form .search-option-area__item",
    function () {
      // 業種or所在地を取得
      var input_name = $(this).parent()[0]["classList"][1];
      // 項目削除
      $(this).remove();
      // 削除後の状態を検索窓及びモーダルへ反映
      var $remaining = $(`ul.search-option-area__list.${input_name}`).children().children();
      if ($remaining.hasClass("search-option-area__link") && $remaining.hasClass("search-option-area__clear")) {
        $(`input[id="${input_name}_${$(this).find("input")[0]["value"]}"]:checked`).prop("checked", false);
      } else {
        $(`td.search-option-table__data.${input_name} .search-option-area`).remove();
        clear_all_checks(`${input_name}`);
      }
      // 検索ヒット数を表示するイベントを発火
      $(`.search-option-table__data.${input_name}`).change();
    }
  );
});

///////////////////////////////////////////////////////////////////////////////////////////////////////////
/////// バリデーション ///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////
// 下限には上限以上の金額は設定できない。上限には下限以下の金額は設定できない。
function set_max_price() {
  if ($("#ma_project_form").length) {
    var min_price = $('#ma_project_form [name="q[non_name_sheet_desired_amount_gteq]"]').val();
    var max_price = $('#ma_project_form [name="q[non_name_sheet_desired_amount_lteq]"]').val();
  } else if ($("#ma_accounting_firm_form").length) {
    var min_price = $('#ma_accounting_firm_form [name="q[desired_amount_gteq]"]').val();
    var max_price = $('#ma_accounting_firm_form [name="q[desired_amount_lteq]"]').val();
  }

  var selectprice = $(".select.max_price").children();
  var options = selectprice.find(`option`);
  options.each(function () {
    if ($(this).val() == "") {
      return true;
    }
    var opt = this;
    if ($(this).parent().get(0).tagName == "SPAN") {
      var span = $(this).parent().get(0);
      $(span).replaceWith(opt);
      $(span).remove();
    }
    opt.disabled = false;
    $(opt).show();
    if (min_price == "") {
      return true;
    }
    if (Number($(this).val().replace(/_/g, "")) <= Number(min_price.replace(/_/g, ""))) {
      $(this).wrap("<span>").hide();
    }
  });
  $(selectprice).find(`option[value="${max_price}"]`).prop("selected", true);
}
function set_min_price() {
  if ($("#ma_project_form").length) {
    var min_price = $('#ma_project_form [name="q[non_name_sheet_desired_amount_gteq]"]').val();
    var max_price = $('#ma_project_form [name="q[non_name_sheet_desired_amount_lteq]"]').val();
  } else if ($("#ma_accounting_firm_form").length) {
    var min_price = $('#ma_accounting_firm_form [name="q[desired_amount_gteq]"]').val();
    var max_price = $('#ma_accounting_firm_form [name="q[desired_amount_lteq]"]').val();
  }

  var selectprice = $(".select.min_price").children();
  var options = selectprice.find(`option`);
  options.each(function () {
    if ($(this).val() == "") {
      return true;
    }
    var opt = this;
    if ($(this).parent().get(0).tagName == "SPAN") {
      var span = $(this).parent().get(0);
      $(span).replaceWith(opt);
      $(span).remove();
    }
    opt.disabled = false;
    $(opt).show();
    if (max_price == "") {
      return true;
    }
    if (Number($(opt).val().replace(/_/g, "")) >= Number(max_price.replace(/_/g, ""))) {
      $(this).wrap("<span>").hide();
    }
  });
  $(selectprice).find(`option[value="${min_price}"]`).prop("selected", true);
}
$(document).on("turbolinks:load", function () {
  $(".select.max_price").focusin(function () {
    set_max_price();
  });
  $(".select.max_price").focusout(function () {
    set_min_price();
  });
  $(".select.min_price").focusin(function () {
    set_min_price();
  });
  $(".select.min_price").focusout(function () {
    set_max_price();
  });

  $("#q_id_in").on("input", function () {
    let value = $(this).val();
    value = value
      .replace(/[０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      })
      .replace(/[^0-9]/g, "");
    $(this).val(value);
  });
});

///////////////////////////////////////////////////////////////////////////////////////////////////////////
/////// 検索処理 ///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////
// リアルタイムで検索ヒット数表示
$(document).on("turbolinks:load", function () {
  // 検索窓で条件を選択した時に件数を表示
  $("#ma_project_form .search__box").change(function () {
    $.ajax({
      type: "GET",
      url: "/top/ma_projects?" + $("#ma_project_form").serialize(),
      dataType: "json"
    })
      .done(function (data) {
        $("span.button-primary__num").html(data.length + "件");
      })
      .fail(function () {
        alert("非同期通信に失敗しました。一度画面を更新してください。");
      });
  });

  $("#ma_accounting_firm_form .search__box").change(function () {
    $.ajax({
      type: "GET",
      url: "/top/ma_accounting_firms?" + $("#ma_accounting_firm_form").serialize(),
      dataType: "json"
    })
      .done(function (data) {
        $("span.button-primary__num").html(data.length + "件");
      })
      .fail(function () {
        alert("非同期通信に失敗しました。一度画面を更新してください。");
      });
  });
});

// ページネーションのボタンを表示（共通処理）
function display_paginator(paginator_html) {
  $(".pagination__box").children().remove();
  $(".pagination__box").append(paginator_html);
}

// 譲渡案件一覧を表示（共通処理）
function display_ma_projects_page(ma_projects_page_html) {
  $(".result-list").children().remove();
  if (ma_projects_page_html == "") {
    $(".result-list").append(`<p class="result__message">検索条件を満たす譲渡案件が見つかりませんでした。</p>`);
    $(".result-control").hide();
  } else {
    $(".result-list").append(ma_projects_page_html);
    $(".result-control").show();
  }
}

// パンくずリストを表示（共通処理）
function display_breadcrumbs_result_page(item) {
  $(".breadcrumbs__result").next().remove();
  if (item !== "") {
    $(".breadcrumbs__result").after(`<li class="breadcrumbs__item">${item}</li>`);
  }
}

// ページネーション
$(document).on("turbolinks:load", function () {
  $("#ma_project_form div.pagination.pagination-top").on("ajax:success", function (data) {
    display_paginator(data.detail[0]["paginator"]);
    display_ma_projects_page(data.detail[0]["ma_projects_page"]);
    $("p.result-control__num").html(data.detail[0]["page_display"]);
  });

  $("#ma_accounting_firm_form div.pagination.pagination-top").on("ajax:success", function (data) {
    display_paginator(data.detail[0]["paginator"]);
    display_ma_projects_page(data.detail[0]["ma_accounting_firms_page"]);
    $("p.result-control__num").html(data.detail[0]["page_display"]);
  });
});

// 検索結果及びソート結果表示
$(document).on("turbolinks:load", function () {
  // 非同期でsubmitして結果を表示（共通処理）
  function submit_and_display_results() {
    $("#overlay").fadeIn(100);
    $.ajax({
      type: "GET",
      url: "/top/ma_projects?" + $("#ma_project_form").serialize(),
      data: { submitted_by: "button" },
      dataType: "json"
    })
      .done(function (data) {
        setTimeout(function () {
          $("#overlay").fadeOut(100);
        }, 100);
        display_paginator(data.paginator);
        display_ma_projects_page(data.ma_projects_page);
        display_breadcrumbs_result_page(data.breadcrumbs_result_page);
        $("p.result-control__num").html(data.page_display);
        $("h1.heading01").html("譲渡案件検索 - 結果一覧");
      })
      .fail(function () {
        $("#overlay").fadeOut(100);
        alert("非同期通信に失敗しました。一度画面を更新してください。");
      });
  }

  function submit_and_display_accounting_firm_results() {
    $("#overlay").fadeIn(100);
    $.ajax({
      type: "GET",
      url: "/top/ma_accounting_firms?" + $("#ma_accounting_firm_form").serialize(),
      data: { submitted_by: "button" },
      dataType: "json"
    })
      .done(function (data) {
        setTimeout(function () {
          $("#overlay").fadeOut(100);
        }, 100);
        display_paginator(data.paginator);
        display_ma_projects_page(data.ma_accounting_firms_page);
        $("p.result-control__num").html(data.page_display);
        $("h1.heading01").html("譲渡案件検索（会計事務所） - 結果一覧");
      })
      .fail(function () {
        $("#overlay").fadeOut(100);
        alert("非同期通信に失敗しました。一度画面を更新してください。");
      });
  }
  // ソートしたときの処理
  const sort_class = "#ma_project_form .result .result-control .result-control__action";
  $(sort_class).off("change");
  $(sort_class).change(function () {
    submit_and_display_results();
  });

  const sort_class_accounting_firm = "#ma_accounting_firm_form .result .result-control .result-control__action";
  $(sort_class_accounting_firm).off("change");
  $(sort_class_accounting_firm).change(function () {
    submit_and_display_accounting_firm_results();
  });

  // 検索ボタンを押したときの処理
  $(".search .ma_project_search .button-box button.button-primary").click(function () {
    submit_and_display_results();
  });

  $(".search .ma_accounting_firm_search .button-box button.button-primary").click(function () {
    submit_and_display_accounting_firm_results();
  });
});

// 重複クリックの防止
// 重複クリックを防止したいリンクに "duplicate-click" クラスを設定する
$(document).on("turbolinks:load", function () {
  $(".duplicate-click").on("click", function () {
    $(this).addClass("disabled");
  });
});
