$(document).on("turbolinks:load", function () {
  new Swiper(".swiper", {
    slidesPerView: "auto", // コンテナ内に表示させるスライド数（CSSでサイズ指定する場合は 'auto'）
    spaceBetween: 20, // スライド間の余白（px）
    centeredSlides: true, // アクティブなスライドを中央に配置する
    loop: true, // 無限ループさせる
    loopAdditionalSlides: 2, // 無限ループさせる場合に複製するスライド数
    autoplay: {
      // 自動再生させる
      delay: 5000 // 次のスライドに切り替わるまでの時間（ミリ秒）
    },

    breakpoints: {
      // ブレークポイント
      641: {
        // 画面幅640px以上で適用
        slidesPerView: "auto",
        spaceBetween: 20 // スライド間の余白（px）
      }
    },

    navigation: {
      //矢印ボタン
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },

    pagination: {
      //ページネーション
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    }
  });
});
