$(document).on("turbolinks:load", function () {
  $(function () {
    // 対象会社（売手）へ開示依頼を送付 /////////////////////////////////////////////////////////////////////////////////////////
    // モーダルを開く
    $("#send_to_seller_comfirm_modal_open").on("click", function () {
      $(".send_to_seller_comfirm").fadeIn();
      return false;
    });
    // モーダルを閉じる
    $("#send_to_seller_comfirm_modal_close").on("click", function () {
      $(".send_to_seller_comfirm").fadeOut();
      return false;
    });

    // PAPへ回答を送信 /////////////////////////////////////////////////////////////////////////////////////////
    // リダイレクト時のフォーム表示
    $("#can_not_consent_lead_sentence").hide();
    $("#no_information_memorandum").removeClass("button-secondary--disabled");
    // フォームの表示切り替え
    $("li.consent").on("click", function () {
      $(this).parent().next().hide();
      $("#can_not_consent_lead_sentence").show();
      $("#no_information_memorandum").addClass("button-secondary--disabled");
    });
    $("li.refusal").on("click", function () {
      $(this).parent().next().show();
      $("#can_not_consent_lead_sentence").hide();
      $("#no_information_memorandum").removeClass("button-secondary--disabled");
    });
    // モーダルを開く
    $("#send_to_pap_comfirm_modal_open").on("click", function () {
      const answer = $("input:radio:checked").next().text();
      $("dd.graybox-list__box.answer_comfirm").html(answer);
      if (answer == "承諾する") {
        $(".refusal_seller_comment").hide();
      } else {
        $(".refusal_seller_comment").show();
        const text = $(".text_area_for_seller_comment").val();
        const new_line_text = text
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#39;")
          .replace(/`/g, "&#x60;")
          .replace(/\r?\n/g, "<br />");
        $("dd.graybox-list__box.refusal_seller_comment_contents").html(new_line_text);
      }
      $(".send_to_pap_comfirm").fadeIn();
      return false;
    });
    // モーダルを閉じる
    $("#send_to_pap_comfirm_modal_close").on("click", function () {
      $(".send_to_pap_comfirm").fadeOut();
      return false;
    });

    // 譲受希望者（買手）へ返答 /////////////////////////////////////////////////////////////////////////////////////////
    // モーダルを開く
    $("#send_to_buyer_comfirm_modal_open").on("click", function () {
      const text_area_class = "text_area_for_pap_comment";
      if ($(this).parent().next().hasClass(text_area_class)) {
        const text = $(`.${text_area_class}`).val();
        const new_line_text = text
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#39;")
          .replace(/`/g, "&#x60;")
          .replace(/\r?\n/g, "<br />");
        $("dd.graybox-list__box.refusal_pap_comment_contents").html(new_line_text);
      }
      $(".send_to_buyer_comfirm").fadeIn();
      return false;
    });
    // モーダルを閉じる
    $("#send_to_buyer_comfirm_modal_close").on("click", function () {
      $(".send_to_buyer_comfirm").fadeOut();
      return false;
    });

    // 譲受希望者（買手）へ返答（売手をスキップ） /////////////////////////////////////////////////////////////////////////////////////////
    // モーダルを開く
    $(".seller_skip_send_to_buyer_comfirm_modal_open").on("click", function () {
      const answer = $("input:radio:checked").next().text();
      $("dd.graybox-list__box.answer_comfirm").html(answer);
      if (answer == "承諾する") {
        $(".refusal_pap_comment").hide();
        $(".consent_comment").show();
      } else {
        $(".refusal_pap_comment").show();
        $(".consent_comment").hide();
        const text = $(".text_area_for_pap_comment").val();
        const new_line_text = text
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#39;")
          .replace(/`/g, "&#x60;")
          .replace(/\r?\n/g, "<br />");
        $("dd.graybox-list__box.refusal_pap_comment_contents").html(new_line_text);
      }
      $(".send_to_buyer_comfirm").fadeIn();
      return false;
    });
    // モーダルを閉じる
    $("#seller_skip_send_to_buyer_comfirm_modal_close").on("click", function () {
      $(".send_to_buyer_comfirm").fadeOut();
      return false;
    });

    // アコーディオンの開閉処理 /////////////////////////////////////////////////////////////////////////////////////////
    $(".contact__head.contact__head--height.contact__head--search").click(function () {
      if ($(this).parent().hasClass("is-opened")) {
        $(this).next().slideUp();
        $(this).next().addClass("is-closed");
        $(this).parent().removeClass("is-opened");
      } else {
        $(this).next().slideDown();
        $(this).next().removeClass("is-closed");
        $(this).parent().addClass("is-opened");
      }
    });
  });

  // 売手スキップ同意チェックボックス
  $("#check_skip_agreement").on("change", function () {
    if (this.checked) {
      $(".button-primary").attr("disabled", false);
    } else {
      $(".button-primary").attr("disabled", true);
    }
  });
});

// 実名開示依頼表示設定
document.addEventListener("turbolinks:load", function () {
  // ページネーションのボタンを表示（共通処理）
  function display_paginator(paginator_html) {
    $(".pagination__box").children().remove();
    $(".pagination__box").append(paginator_html);
  }

  // 実名開示依頼一覧を表示（共通処理）
  function display_disclosure_requests_page(disclosure_requests_page_html) {
    $(".js-disclosure_requests").remove();
    if (disclosure_requests_page_html !== "") {
      $(".result-control--upper").after(disclosure_requests_page_html);
      $(".result-control").show();
    }
  }

  // ページネーション
  $(function () {
    $("div.pagination.pagination-project").on("ajax:success", function (data) {
      console.log(data); // dataを確認する
      display_paginator(data.detail[0]["paginator"]);
      display_disclosure_requests_page(data.detail[0]["disclosure_requests_page"]);
      $("p.result-control__num").html(data.detail[0]["page_display"]);
    });
  });

  $(function () {
    // 非同期でsubmitして結果を表示（共通処理）
    $(".js-pap-archive-hidden-checkbox").change(function () {
      $.ajax({
        type: "GET",
        url: `disclosure_requests`,
        data: {
          show_archive: $("#show_archive")[0].checked
        },
        dataType: "json"
      })
        .done(function (data) {
          console.log(data); // dataを確認する
          history.replaceState("", "", `?show_archive=${$("#show_archive")[0].checked}`);
          display_paginator(data.paginator);
          display_disclosure_requests_page(data.disclosure_requests_page);
          $("p.result-control__num").html(data.page_display);
        })
        .fail(function (data) {
          console.log(data); // dataを確認する
          alert("非同期通信に失敗しました");
        });
    });
  });
});
