$(document).on("turbolinks:load", function () {
  if (performance.getEntriesByType("navigation").length > 0) {
    if (performance.getEntriesByType("navigation")[0].type === "reload") {
      if ($("#detect_reload_pap").length) {
        window.location.href = "/pap/mypage/customer_informations/emails/edit";
      } else if ($("#detect_reload_seller").length) {
        window.location.href = "/seller/mypage/customer_informations/emails/edit";
      } else if ($("#detect_reload_buyer").length) {
        window.location.href = "/buyer/mypage/customer_informations/emails/edit";
      } else if ($("#detect_reload_buyer_fa").length) {
        window.location.href = "/buyer_fa/mypage/customer_informations/emails/edit";
      } else if ($("#detect_reload_buyer_fa_visitor").length) {
        window.location.href = "/buyer_fa_visitor/mypage/customer_informations/emails/edit";
      }
    }
  }
});
