// モーダル共通設定
// モーダル表示
function show_modal(modal, model_name = null, open_button = null) {
  if (model_name == "upload_document") set_target_file_name(open_button);
  modal.fadeIn();
  return false;
}
// モーダル非表示
function hide_modal(modal, input_area = null, upload_button = null, file_flag = false) {
  if (file_flag) delete_file(input_area, upload_button);
  modal.fadeOut();
  return false;
}
$(document).on("click touchend", function (event) {
  // 表示したポップアップ以外の部分をクリックしたときモーダルを閉じる
  if ($(event.target)[0].className !== "reg-dd__item" && !$(event.target).closest(".modal-split").length) {
    $(".overlay").fadeOut();
  }
});

// モーダルの開閉（共通処理）
// １．モーダルをパーシャル化し、モーダルを開きたいページのどこかでrenderしておく。
// ２．モーダルを開きたいボタンに、"◯◯-modal-open"というidを設定。（◯◯はご自由に）
// ３．モーダルを閉じたいボタンに、"◯◯-modal-close"というidを設定。（◯◯は上記と同じ）
// ４．引数はそれぞれ下記のとおり設定する。
//     modal_name : 上記の◯◯
//     modal_class : パーシャル化したモーダル内の一番上位の要素のクラス
function set_modal(modal_name, modal_class) {
  const modal = $(modal_class);
  const open_modal_button = $(`#${modal_name}-modal-open`);
  const close_modal_button = $(`#${modal_name}-modal-close`);

  // モーダルを開く
  open_modal_button.on("click", () => show_modal(modal));
  // モーダルを閉じる
  close_modal_button.on("click", () => hide_modal(modal));
}
$(document).on("turbolinks:load", function () {
  // 登録確認モーダル
  set_modal("confirm-actor", ".confirm-actor-modal");
  set_modal("confirm-actor-seller", ".confirm-actor-modal");
  set_modal("industry", ".select-industry");
  // 譲渡案件検索窓における業種選択（その他の処理はtop.js参照）
  set_modal("industry", ".select-industry");
  // 譲渡案件検索窓における所在地選択（その他の処理はtop.js参照）
  set_modal("area", ".select-area");
  // 企業概要書CSV取り込みモーダル
  set_modal("select-financial-statement", ".select-financial-statement-modal");
  // 企業概要書削除モーダル
  set_modal("delete-im-file", ".delete-im-file-modal");
  // M&A進捗状況における完了報告確認モーダル(PAP)
  set_modal("statuschange-dd", ".statuschange_dd_modal");
  set_modal("statuschange-mou", ".statuschange_mou_modal");
  set_modal("statuschange-da", ".statuschange_da_modal");
  set_modal("cancel-matching", ".cancel_matching_modal");
  // 利用者グループ権限管理（PAP）
  set_modal("add-confirm", ".add-confirm");
  set_modal("cancel-confirm", ".cancel-confirm");
  set_modal("resend-mail-confirm", ".resend-mail-confirm");
  set_modal("authority-confirm", ".authority-confirm");
  set_modal("delete-confirm", ".delete-confirm");
  // 会計事務所案件掲載ステータス更新確認モーダル
  set_modal("update-status", ".update-status-modal");
  // 実名開示依頼一覧（買手）の取引中止確認モーダル
  set_modal("withdraw-disclosure", ".withdraw-disclosure");
  // 意向表明書(アップロードファイル)削除モーダル
  set_modal("delete-loi-file", ".delete-loi-file-modal");
  // 意向表明書(作成ファイル)削除モーダル
  set_modal("delete-create-file", ".delete-create-file-modal");
  // マッチングオファー作成（買手）の送信確認モーダル
  set_modal("create-letter-of-intent", ".create-letter-of-intent");
  // 買手（未・済）削除モーダル
  set_modal("buyer-fa-visitor-destroy", ".buyer-fa-visitor-destroy-modal");
  // レコメンドにおける所在地選択
  set_modal("select-area", ".select-area");
  // レコメンド削除モーダル
  set_modal("recommend-destroy-1", ".recommend-destroy-1-modal");
  set_modal("recommend-destroy-2", ".recommend-destroy-2-modal");
  set_modal("recommend-destroy-3", ".recommend-destroy-3-modal");
  // 会計事務所案件作成確認モーダル
  set_modal("new-pap-accounting-firm-registration", ".new-pap-accounting-firm-registration-modal");
  // PAPモード切替確認モーダル
  set_modal("mode-ag-switching", ".mode-ag-switching-modal");
  set_modal("mode-buyerfa-switching", ".mode-buyerfa-switching-modal");
  // ログアウト
  set_modal("js", ".js-modal");

  // 利用者グループ登録処理
  $(document).on("click", "#register-confirm-modal-close", () => {
    hide_modal($(".register-confirm-modal"));
  });
  // サービス利用停止 - 申請
  $(document).on("click", "#confirm-modal-close", () => {
    hide_modal($(".confirm-modal"));
  });
});

// モーダルの開閉（共通処理）（一覧などの各項目において動的にモーダルを開きたい場合）
// １．モーダルをパーシャル化し、モーダルを開きたいボタンの次の要素でrenderしておく。
// ２．モーダルを開きたいボタンに、"◯◯-modal-open"というclassを設定。（◯◯はご自由に）
// ３．モーダルを閉じたいボタンに、"◯◯-modal-close"というclassを設定。（◯◯は上記と同じ）
// ４．引数はそれぞれ下記のとおり設定する。
//     modal_name : 上記の◯◯ (uniqueであること)
//     modal_class : パーシャル化したモーダル内の一番上位の要素のクラス(uniqueでなくとも可)
function set_each_modal(modal_name, modal_class) {
  const open_modal_button = $(`.${modal_name}-modal-open`);
  const close_modal_button = $(`.${modal_name}-modal-close`);

  // モーダルを開く
  open_modal_button.on("click", (e) => show_modal($(e.currentTarget).next()));
  // モーダルを閉じる
  close_modal_button.on("click", (e) => hide_modal($(e.currentTarget).closest(modal_class)));
}
$(document).on("turbolinks:load", function () {
  // 利用者グループに使用するモーダル開閉
  set_each_modal("business-group", ".business-group-confirm-modal");
  // データアップロード画面削除モーダル文言
  set_each_modal("delete-upload-document", ".delete-upload-document-modal");
  // データアップロード画面会計書類削除モーダル文言
  set_each_modal("delete-accountant-document", ".delete-accountant-document-modal");
  // 価値算定書画面削除モーダル文言
  set_each_modal("delete-cvcd", ".delete-cvcd-modal");
  set_each_modal("reference", ".reference-modal");
});

// ファイルアップロードに使用するモーダル開閉
function set_file_upload_modal(model_name, file_name) {
  const modal = $(`#${model_name}_${file_name}_modal`);
  const open_button = $(`.${model_name}_${file_name}-upload`);
  const close_button = $(`#${model_name}_${file_name}_modal_close`);
  const upload_button = $(`.${model_name}_${file_name}-upload-button`);
  const drop_area = $(`#${file_name}_drop-area`);
  const input_area = $(`#${model_name}_${file_name}`);

  // モーダルの表示
  open_button.on("click", (e) => show_modal(modal, model_name, $(e.currentTarget)));
  // 閉じる際に選択ファイルの解除、アップロードするボタンの非活性化を行う。
  close_button.on("click", () => hide_modal(modal, input_area, upload_button, true));
  // ファイルアップロードモーダルのドラッグアンドドロップ制御
  drag_and_drop_file(drop_area, model_name, file_name, upload_button);
  // ファイルアップロードモーダルの選択ファイル表示制御用
  select_file(input_area, upload_button);

  $(document).on("click touchend", function (event) {
    // 表示したポップアップ以外の部分をクリックしたときモーダルを閉じる
    if ($(event.target)[0].className !== "reg-dd__item" && !$(event.target).closest(".modal-split").length) {
      if ($(".modal-split").length) {
        delete_file(input_area, upload_button);
      }
      $(".overlay").fadeOut();
    }
  });
}
// ドラッグ&ドロップされたファイルをinputタグのfileの値として設定
function drag_and_drop_file(drop_area, model_name, file_name, upload_button) {
  drop_area.on("dragover", function (e) {
    e.preventDefault();
  });
  drop_area.on("drop", function (e) {
    e.preventDefault();

    // アップロードされたファイルが1個でなければreturn
    if (e.originalEvent.dataTransfer.files.length != 1) return;

    // モデルごとにアップロードを許可する拡張子を設定
    const allow_extensions = set_allow_extnsions(model_name);
    // 許可している拡張子でなければreturn
    if (!e.originalEvent.dataTransfer.files[0].name.match(allow_extensions)) return;

    document.getElementById(`${model_name}_${file_name}`).files = e.originalEvent.dataTransfer.files;
    const file = $(`#${model_name}_${file_name}`).prop("files");
    $("li.reg-dd__item").remove();
    $("ul.reg-dd__list").append(`<li class="reg-dd__item">${file[file.length - 1].name}</li>`);
    upload_button.prop("disabled", false);
  });
}
// アップロードを許可する拡張子を設定
function set_allow_extnsions(model_name) {
  switch (model_name) {
    case "upload_document":
      return ".(jpg|jpeg|png|pdf|txt|csv|docx|xlsx|pptx)$";
    case "accountant_document":
      return ".(csv)$";
    case "ma_project":
      return ".(pdf)$";
    case "letter_of_intent":
      return ".(pdf)$";
    default:
      break;
  }
}
// 選択されたファイルをinputタグのfileの値として設定
function select_file(input_area, upload_button) {
  let files;
  input_area.on("click", function () {
    files = $(this).prop("files");
  });
  input_area.on("change", function () {
    const file = $(this).prop("files");
    if (!file.length) {
      $(this)[0].files = files;
      return;
    }
    $("li.reg-dd__item").remove();
    $("ul.reg-dd__list").append(`<li class="reg-dd__item">${file[0].name}</li>`);
    upload_button.prop("disabled", false);
  });
  // 選択ファイルを消去する
  $(document).on("click", ".reg-dd__item", () => {
    if ($(".modal-split").length) {
      delete_file(input_area, upload_button);
    }
  });
}
$(document).on("turbolinks:load", function () {
  // データアップロードモーダル用
  set_file_upload_modal("upload_document", "file");
  // 会計データアップロードモーダル用
  set_file_upload_modal("accountant_document", "ac_file");
  // 企業概要書アップロードモーダル用
  set_file_upload_modal("ma_project", "im_file");
  // 意向表明書アップロードモーダル用
  set_file_upload_modal("letter_of_intent", "loi_file");
});

// アップロード対象の名前をモーダルのタイトルとして表示
// 例: 事業計画書をアップロードしてください
function set_target_file_name(open_button) {
  const type = open_button.attr("data-type");
  const type_name = open_button.attr("data-title");
  $("#upload_document_division").prop("value", type);
  $("#document-title").text(`${type_name}をアップロードしてください`);
}
// アップロードされたファイルを空にして、アップロードボタンを無効化
function delete_file(input_area, upload_button) {
  $(".reg-dd__item").remove();
  input_area.val("");
  upload_button.prop("disabled", true);
}
