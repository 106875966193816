import "../stylesheets/application_responsive"; // This file will contain your custom CSS
require("turbolinks").start();
var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
require("daterangepicker");
require("moment");
require("popper.js/dist/popper.js");
require("jquery-3.6.0.min.js");
require("header.js");
require("footer.js");
require("modal.js");
require("auto_updating_flag");
require("calendar");
require("regists.js");
require("message.js");
require("question.js");
require("top.js");
require("information_memorandums.js");
require("modules/buyer/disclosure_requests.js");
require("letter_of_intents.js");
require("modules/pap/disclosure_requests.js");
require("project_pagenation.js");
require("get_address.js");
require("modules/pap/agent_registrations.js");
require("modules/pap/proxy_registrations.js");
require("modules/pap/service_suspension_requests.js");
require("flash");
require("corporate_value_calc_documents");
require("ma_project_information");
require("first_recommend_modal");
require("notices");
require("faq");
require("emails");
require("only_num");
require("modules/buyer_fa/disclosure_requests.js");
require("carousel");
require("archive_modal");
require("modules/buyer/archive_pagenation.js");
require("modules/buyer_fa/archive_pagenation.js");
// 画像読み込み
const images = require.context("../images/", true);
const imagePath = (name) => images(name, true);

// document.addEventListener("turbolinks:load", () => {
//   $('[data-toggle="tooltip"]').tooltip();
// });

var Rails = require("@rails/ujs");
window.Rails = Rails;
Rails.start();
