// 閲覧モードー記入モードの切り替え
$(document).on("turbolinks:load", function () {
  const form_class = ".comment-editor__inner .comment-editor__wrapper";
  const box_class_message = "#edit-message .button-box";
  const mergin_class_name = "comment-editor__wrapper--only";
  // フォームをクリックすると、閲覧モードから記入モードへ
  $(form_class).click(function () {
    $(box_class_message).show();
    $(form_class).removeClass(mergin_class_name);
  });
  // 「閉じる」ボタン押下で、記入モードから閲覧モードへ戻る
  $(".button-box .button-back").click(function () {
    $(box_class_message).hide();
    $(form_class).addClass(mergin_class_name);
  });
});

// 記入モードープレビューの切り替え
$(document).on("turbolinks:load", function () {
  // before_classを非表示にし、after_classを表示させる（共通処理）
  function hide_and_show(before_class, after_class) {
    $(before_class).hide();
    $(after_class).show();
  }
  const edit_button = "#show-preview";
  const preview_button = "#edit-message";
  const index_area = "#default_index";
  const preview_area = "#preview_content";
  // 「プレビュー」ボタン押下で、記入モードからプレビューへ
  $("#edit-message .button-box .button-secondary").click(function () {
    hide_and_show(preview_button, edit_button);
    hide_and_show(index_area, preview_area);
    // プレビュー内容表示
    $(preview_area)
      .find(".message-balloon__type-detail")
      .html($(".select.middle-size").find("option:selected")[0].label);
    $(preview_area).find(".message-balloon__text").text($(".input-textarea")[0].value);
    // プレビュー位置までスクロール
    scrollTo(0, $(".message-group#preview_content").offset().top - 100);
  });
  // 「編集する」ボタン押下で、プレビューから記入モードへ戻る
  $("#show-preview .button-box .button-secondary").click(function () {
    hide_and_show(edit_button, preview_button);
    hide_and_show(preview_area, index_area);
  });
});

// テキストボックス高さ調整
$(document).on("turbolinks:load", function () {
  const textarea_class = ".comment-editor__comment .input-textarea";
  const textarea_lineHeight = parseInt($(textarea_class).css("line-height"));
  const textarea_max_height = parseInt($(textarea_class).css("max-height"));
  $(textarea_class).on("input click", function (e) {
    const lines = ($(this).val() + "\n").match(/\n/g).length;
    $(this).height(Math.min(textarea_lineHeight * lines, textarea_max_height));
  });
});

// 重要ラベルの登録・表示
$(document).on("turbolinks:load", function () {
  // 非同期でDBをupdate（共通処理）
  function toggle_important_flag(update_path, new_flag) {
    // const path_elements = location.pathname.split("/"); // notice経由で機能せず
    const path_elements = update_path.split("/");
    const parameter = { important_flag: new_flag };
    if (path_elements.includes("messages")) {
      var update_params = { message: parameter };
    } else if (path_elements.includes("questions")) {
      var update_params = { question: parameter };
    }
    $.ajax({
      type: "PATCH",
      url: update_path,
      data: update_params,
      dataType: "json"
    }).fail(function () {
      alert("非同期通信に失敗しました。一度画面を更新してください。");
    });
  }
  // 各メッセージの重要ラベルのON/OFF
  const important_label_class = ".message-balloon__label";
  const important_label_active = "message-balloon__label--active";
  $(important_label_class).click(function () {
    const update_path = $(this).parent().attr("name");
    if ($(this).hasClass(important_label_active)) {
      toggle_important_flag(update_path, false);
      $(this).removeClass(important_label_active);
    } else {
      toggle_important_flag(update_path, true);
      $(this).addClass(important_label_active);
    }
  });
  // 各メッセージの重要ラベルホバー時のツールチップの内容を切り替え
  $(important_label_class).on("mouseenter click", function () {
    if ($(this).hasClass(important_label_active)) {
      $(this).next().text("重要ラベルをはずす");
    } else {
      $(this).next().text("重要ラベルをつける");
    }
  });
  // 重要ラベルのみ表示（ON/OFF）
  const send_messages_class = ".message-balloon.message-balloon__send";
  const receive_messages_class = ".message-balloon.message-balloon__receive";
  const unread_title_class = "h3.message-unlead";
  const only_important_label = "is-active";
  const no_important_class = "p.result__message.no_important";
  $("section.message-heading button.button-label").click(function () {
    if ($(this).hasClass(only_important_label)) {
      // 全件表示に戻す
      $(send_messages_class).show();
      $(receive_messages_class).show();
      $(unread_title_class).show();
      // 不要な要素を非表示
      $(no_important_class).hide();
      // ボタンのクラスを切り替え
      $(this).removeClass(only_important_label);
    } else {
      // 一度全件非表示
      $(send_messages_class).hide();
      $(receive_messages_class).hide();
      $(unread_title_class).hide();
      // 重要ラベルがついているメッセージがあれば表示
      if ($(important_label_class).hasClass(important_label_active)) {
        $(".message-balloon__label--active").parent().parent().show();
      } else {
        $(no_important_class).show();
      }
      // ボタンのクラスを切り替え
      $(this).addClass(only_important_label);
    }
  });
});

// ページ更新時、未読位置までスクロール
$(document).on("turbolinks:load", function () {
  if ($(".message-heading").length) {
    if ($("#js-unread-position").length) {
      scrollTo(0, $("#js-unread-position").offset().top - 100);
    } else if ($(".message-balloon").length >= 2) {
      scrollTo(0, $(".message-balloon:nth-last-child(2)").offset().top - 100);
    }
  }
});
