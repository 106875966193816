$(document).on("click", ".archive-modal-open", function () {
  const archive_id = $(this).val();
  $(`.archive-modal-${archive_id}`).fadeIn();
  return false;
});

$(document).on("click", ".archive-modal-close", function () {
  const archive_id = $(this).val();
  $(`.archive-modal-${archive_id}`).fadeOut();
  return false;
});
