$(document).on("turbolinks:load", function () {
  // 秘密保持誓約同意チェックボックス
  $("#check_agreement").on("change", function () {
    if (this.checked) {
      $(".button-primary").attr("disabled", false);
    } else {
      $(".button-primary").attr("disabled", true);
    }
  });

  // 例文入力チェックボックス
  $("#buyer_disclosure_request").on("change", function () {
    if (this.checked && $("#request_message").val().length == 0) {
      $("#request_message").val(
        `例）
         お世話になります。〇〇です。弊社（私）は○○という事業行っており、御社の○○【承継したい理由・惹かれたポイント等を記載】という点に大変関心を持ち、お問い合わせ致しました。買収予算は○○円～○○円程度を想定しております。ぜひ実名ベースでの交渉をお願いしたく存じます。
         実名開示依頼を申請いたしますので、ご承認いただけると幸いです。
         お忙しいところ恐縮ですが、何卒よろしくお願いいたします。`
      );
    }
  });
});
