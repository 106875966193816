$(document).on("turbolinks:load", function () {
  $(function () {
    // 他の画面からヘッダーの「よくある質問」リンクで遷移してきた時にタブをアクティブにする
    if (location.hash.includes("faq")) {
      $(location.hash).prop("checked", true);
    }

    // よくある質問画面でヘッダーの「よくある質問」を押した時にタブをアクティブにする
    $(".header-nav-list__item").click(function () {
      let str = $(this).find(".header-nav-list__link").attr("href");
      str = str.substring(str.indexOf("#"), str.length);
      $(str).prop("checked", true);
    });
  });
});
