$(document).on("turbolinks:load", function () {
  $(function () {
    $(".drop_reason_check").click(function () {
      $(".drop_reason_area").show();
    });
    $(".drop_reason_close").click(function () {
      $(".drop_reason_area").hide();
    });
  });

  // ノンネームシートコピーモーダル
  $(function () {
    set_modal("non_name_sheet_copy", "non_name_sheet_copy_modal");
  });
});

function set_modal(modal_name, modal_class) {
  // モーダルを開く
  $(`.${modal_name}-modal-open`).on("click", function () {
    $(`.${modal_class}`).fadeIn();
    return false;
  });
  // モーダルを閉じる
  $(`.${modal_name}-modal-close`).on("click", function () {
    $(`.${modal_class}`).fadeOut();
    return false;
  });
}

$(document).on("turbolinks:load", function () {
  $(function () {
    set_modal("fixed", "fixed-modal");
  });
});
