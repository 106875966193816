document.addEventListener("turbolinks:load", function () {
  // ページネーションのボタンを表示（共通処理）
  function display_paginator(paginator_html) {
    $(".pagination__box").children().remove();
    $(".pagination__box").append(paginator_html);
  }

  // 譲渡案件一覧を表示（共通処理）
  function display_seller_business_groups_page(seller_business_groups_page_html) {
    $(".box-message").remove();
    $(".result").remove();
    $(".js-no-seller-groups-text").remove();
    if (seller_business_groups_page_html !== "") {
      $(".result-control--upper").after(seller_business_groups_page_html);
      $(".result-control").show();
    }
  }

  // ページネーション
  $(function () {
    $("div.pagination.pagination-project").on("ajax:success", function (data) {
      console.log(data); // dataを確認する
      display_paginator(data.detail[0]["paginator"]);
      display_seller_business_groups_page(data.detail[0]["seller_business_groups_page"]);
      $("p.result-control__num").html(data.detail[0]["page_display"]);
    });
  });

  $(function () {
    // 非同期でsubmitして結果を表示（共通処理）
    $(".js-hidden-checkbox").change(function () {
      $.ajax({
        type: "GET",
        url: "/pap/mypage/sellers",
        data: {
          show_closed: $("#show_closed")[0].checked,
          show_unmanaged: $("#show_unmanaged")[0].checked
        },
        dataType: "json"
      })
        .done(function (data) {
          console.log(data); // dataを確認する
          history.replaceState(
            "",
            "",
            `?show_closed=${$("#show_closed")[0].checked}&show_unmanaged=${$("#show_unmanaged")[0].checked}`
          );
          display_paginator(data.paginator);
          display_seller_business_groups_page(data.seller_business_groups_page);
          $("p.result-control__num").html(data.page_display);
        })
        .fail(function (data) {
          console.log(data); // dataを確認する
          alert("非同期通信に失敗しました");
        });
    });
  });
});
