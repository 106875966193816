$(document).on("turbolinks:load", function () {
  $(function () {
    $(".header__menu").click(function () {
      $(this).toggleClass("is-opened");
      $(".header__navigation").toggleClass("is-opened");
    });

    $(".header-nav__item").click(function () {
      // $(".header-nav__item").removeClass("is-opened");
      const index = $(this).index();
      $(".header-nav__item").eq(index).toggleClass("is-opened");
    });
  });
});
