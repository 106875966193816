$(document).on("turbolinks:load", function () {
  $(".only_num").on("change", function () {
    let value = $(this).val();
    // 全角数字を半角数字に変換
    value = value.replace(/[０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    });
    // 数字以外を削除
    value = value.replace(/[^0-9]/g, "");
    $(this).val(value);
  });
});
