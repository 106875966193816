$(document).on("turbolinks:load", function () {
  // 「一時保存」「送付する」「意向表明書プレビュー」のリンク出し分け
  const temp_save_button = "button.button-action";
  const preview_link = "a.preview-link__help-link";
  $("section.bayer__section").on("change keyup", function () {
    $(temp_save_button).attr("disabled", false);
    $(preview_link).attr("disabled", true);
  });

  // 「送付する」ボタン制御用
  $(".js-agree-check").on("change", function () {
    if ($(".js-agree-check:not(:checked)").length == 0) {
      $("#create-letter-of-intent-modal-open").attr("disabled", false);
      $("#loi_submit_button").attr("disabled", false);
    } else {
      $("#create-letter-of-intent-modal-open").attr("disabled", true);
      $("#loi_submit_button").attr("disabled", true);
    }
  });

  // 例文入力チェックボックス（共通処理）
  function insert_example_to_textarea(insert_class, textarea_class, content) {
    $(insert_class).on("change", function () {
      if (this.checked && $(textarea_class).val().length == 0) {
        $(textarea_class).val(content);
      }
    });
  }
  // 主文
  insert_example_to_textarea(
    "#insert_main_text",
    "#textarea_main_text",
    `例）この度は、このような機会をいただき誠に有難うございます。
    貴社の株式譲渡案件（以下「本件」といいます）に対し、下記の通り、意向を表明いたします。`
  );
  // 本件取引を希望する理由
  insert_example_to_textarea(
    "#insert_reason",
    "#textarea_reason",
    `例）当社は、今後の計画においてXX事業の拡大を予定しております。そのため、貴社の株式を譲受することにより事業の成長をより加速させたいと考えております。また、貴社のXX事業は、当社事業との親和性が高く、両者に高い利益をもたらすものと考えております。`
  );
  // 取引方法
  insert_example_to_textarea(
    "#insert_deal_way",
    "#textarea_deal_way",
    `例）貴社発行済み株式をすべて譲受させていただきたく存じます。`
  );
  // 本件取引の前提となる条件、要望
  insert_example_to_textarea(
    "#insert_precondition",
    "#textarea_precondition",
    `例）
    ・代表者（〇〇さま）には、本件取引完了後も引き続きご支援いただくことを希望します。
    ・従業員の雇用については、現状維持を前提といたします。
    ・本件取引完了後、取締役を数名派遣させていただく予定です。`
  );
  // 専門家のサポート
  insert_example_to_textarea(
    "#insert_expert_arrange",
    "#textarea_expert_arrange",
    `例）
    当社は、〇〇会計事務所をアドバイザーとして選任し、以後の本件取引について依頼いたします。`
  );
  // デューデリジェンスについて
  insert_example_to_textarea(
    "#insert_due_diligence",
    "#textarea_due_diligence",
    `例）当社は、貴社の主要取引先、契約内容、財務状況、労働環境等に関するデューデリジェンスを実施させていただきたく存じます。また、デューデリジェンスにあたり貴社のご協力を賜ることをお願いいたします。なお、具体的な調査方法や実施日等については、別途ご相談いたします。`
  );

  // 譲受け後の運営方針(会計事務所)
  insert_example_to_textarea(
    "#insert_management_policy",
    "#textarea_management_policy",
    `例）\n事務所：支店展開を検討しているためそのままの事務所として継続いただきたい\n職員：職員の方には全員残って事務所の拡大に貢献し欲しい\n所長：無理のない範囲で事務所に残っていただき、新所長のサポートをしてほしい\n顧問先：当面は現行の方針を継続し、無理のない引継ぎを実施したい`
  );

  // アピールポイント(会計事務所)
  insert_example_to_textarea(
    "#insert_appeal_point",
    "#textarea_appeal_point",
    `例）\n今までも会計事務所の譲受を●●件行っており、職員や顧問先に対し無理のない引継ぎを行っております。`
  );

  // 専門家のサポート入力フォーム切り替え
  $("label.ask_pap").on("click", function () {
    $(this).closest("ul").next().hide();
    $("#textarea_expert_arrange").prop("disabled", true);
  });
  $("label.self_arrange").on("click", function () {
    $(this).closest("ul").next().show();
    $("#textarea_expert_arrange").prop("disabled", false);
  });

  // オファー取り下げモーダル //////////////////////////////////////////////
  $(function () {
    // モーダルを開く
    $(".withdraw-letter-of-intent-modal-open").on("click", function () {
      const letter_of_intent_id = $(this).val();
      $(`.withdraw-letter-of-intent-modal-${letter_of_intent_id}`).fadeIn();
      return false;
    });
    // モーダルを閉じる
    $(".withdraw-letter-of-intent-modal-close").on("click", function () {
      const letter_of_intent_id = $(this).val();
      $(`.withdraw-letter-of-intent-modal-${letter_of_intent_id}`).fadeOut();
      return false;
    });
  });

  //  買手FAの買手(未・済)選択モーダル
  set_modal("select-buyer", ".select-buyer-modal");
});

function set_modal(modal_name, modal_class) {
  // モーダルを開く
  $(`.${modal_name}-modal-open`).on("click", function () {
    $(modal_class).fadeIn();
    return false;
  });
  // モーダルを閉じる
  $(`.${modal_name}-modal-close`).on("click", function () {
    $(modal_class).fadeOut();
    return false;
  });
}
