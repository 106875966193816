PRICES = { no_max_limit: 0, one_ten_million: 1, three_ten_million: 2, five_ten_million: 3, one_hundred_million: 4 };

function recommend_modal_set_max_price(target) {
  let min_price = target.val();
  let select_high = target.closest(".search-option-price").find(".js-select-high");
  let max_price = select_high.val();
  let options = select_high.find("option");
  options.each(function () {
    if (PRICES[$(this).val()] == 0) {
      return true;
    }
    var opt = this;
    if ($(this).parent().get(0).tagName == "SPAN") {
      var span = $(this).parent().get(0);
      $(span).replaceWith(opt);
      $(span).remove();
    }
    opt.disabled = false;
    $(opt).show();
    if (PRICES[min_price] == 0) {
      return true;
    }
    if (PRICES[$(this).val()] <= PRICES[min_price]) {
      $(this).wrap("<span>").hide();
    }
  });
  $(select_high).find(`option[value="${max_price}"]`).prop("selected", true);
}

function recommend_modal_set_min_price(target) {
  var max_price = target.val();
  let select_low = target.closest(".search-option-price").find(".js-select-low");
  var min_price = select_low.val();
  var options = select_low.find("option");
  options.each(function () {
    if (PRICES[$(this).val()] == 0) {
      return true;
    }
    var opt = this;
    if ($(this).parent().get(0).tagName == "SPAN") {
      var span = $(this).parent().get(0);
      $(span).replaceWith(opt);
      $(span).remove();
    }
    opt.disabled = false;
    $(opt).show();
    if (PRICES[max_price] == 0) {
      return true;
    }
    if (PRICES[$(this).val()] >= PRICES[max_price]) {
      $(this).wrap("<span>").hide();
    }
  });
  $(select_low).find(`option[value="${min_price}"]`).prop("selected", true);
}

$(function () {
  // 進む場合の挙動
  $(document).on("click", ".js-first-recommend-modal-forward", function () {
    let current_step = $(this).data("step");
    let next_step = current_step + 1;
    $(`#step${current_step}`).hide();
    $(`#step${next_step}`).show();
  });
  // 戻る場合の挙動
  $(document).on("click", ".js-first-recommend-modal-back", function () {
    let current_step = $(this).data("step");
    let prev_step = current_step - 1;
    $(`#step${current_step}`).hide();
    $(`#step${prev_step}`).show();
  });
  // 登録スキップの挙動
  $(document).on("click", "#first_recommend_modal_skip", function () {
    $("#overlay").fadeIn(100);
    $.ajax({
      type: "POST",
      url: $("#first_recommend_form").attr("action"),
      data: { skip: true },
      dataType: "json"
    })
      .done(function (data) {
        setTimeout(function () {
          $("#overlay").fadeOut(100);
        }, 100);
        if (data["succeed"] == true) {
          $(".overlay--pap-alert.recommend-modal").fadeOut();
        } else {
          alert("処理に失敗しました。一度画面を更新してください。");
        }
      })
      .fail(function () {
        $("#overlay").fadeOut(100);
        alert("非同期通信に失敗しました。一度画面を更新してください。");
      });
  });

  $(document).on("change", "#recommend_industry_large_id", function () {
    if ($(this).val()) {
      $("#step1_forward").prop("disabled", false);
    } else {
      $("#step1_forward").prop("disabled", true);
    }
  });

  $(document).on("change", ".js-select-low", function () {
    recommend_modal_set_max_price($(this));
  });
  $(document).on("change", ".js-select-high", function () {
    recommend_modal_set_min_price($(this));
  });

  // 登録ボタン押下時の挙動
  $(document).on("click", "#first_recommend_modal_confirm", function () {
    $("#overlay").fadeIn(100);
    $.ajax({
      type: "POST",
      url: $("#first_recommend_form").attr("action"),
      data: $("#first_recommend_form").serialize(),
      dataType: "json"
    })
      .done(function (data) {
        setTimeout(function () {
          $("#overlay").fadeOut(100);
        }, 100);
        if (data["succeed"] == true) {
          $("#step3").hide();
          $("#step4").show();
        } else {
          alert("処理に失敗しました。一度画面を更新してください。");
        }
      })
      .fail(function () {
        $("#overlay").fadeOut(100);
        alert("非同期通信に失敗しました。一度画面を更新してください。");
      });
  });
  // 地域のチェックボックス押下時の挙動
  $(document).on("click", "input.js-area-large-check", function () {
    let children_check_box = $(this).closest("li.select-area__item").find("input.js-prefecture-code");
    if ($(this).prop("checked")) {
      children_check_box.prop("checked", true);
    } else {
      children_check_box.prop("checked", false);
    }
    if ($("input.js-prefecture-code:checked").length == 0) {
      $("#step2_forward").prop("disabled", true);
    } else {
      $("#step2_forward").prop("disabled", false);
    }
  });
  // 都道府県のチェックボックス押下時の挙動
  $(document).on("click", "input.js-prefecture-code", function () {
    let parent_check_box = $(this).closest("li.select-area__item").find("input.js-area-large-check");
    let check_all = $(this).closest("li.select-area__item").find("input.js-prefecture-code:not(:checked)").length == 0;
    if (check_all) {
      parent_check_box.prop("checked", true);
    } else {
      parent_check_box.prop("checked", false);
    }
    if ($("input.js-prefecture-code:checked").length == 0) {
      $("#step2_forward").prop("disabled", true);
    } else {
      $("#step2_forward").prop("disabled", false);
    }
  });

  // クリアボタン押下時の挙動
  $(document).on("click", "#recommend_modal_area_clear", function () {
    $("input.js-area-large-check").prop("checked", false);
    $("input.js-prefecture-code").prop("checked", false);
    $("#step2_forward").prop("disabled", true);
  });

  // マイページ確認リンク押下時
  $(document).on("click", "#close_first_recommend_modal", function () {
    $(".overlay--pap-alert.recommend-modal").fadeOut();
  });
});
