function toggle_text_field(text_field) {
  // 期間設定の自動更新ラジオボタン
  if ($(".auto_updating_false").prop("checked")) {
    $(text_field).attr("disabled", false);
    $(text_field).removeClass("is-disabled");
  }
  $(".auto_updating_flag").on("change", function () {
    if ($(".auto_updating_false").prop("checked")) {
      $(text_field).attr("disabled", false);
      $(text_field).removeClass("is-disabled");
    } else {
      $(text_field).attr("disabled", true);
      $(text_field).addClass("is-disabled");
    }
  });
}

$(document).on("turbolinks:load", function () {
  $(function () {
    toggle_text_field("#post_period_on");
    toggle_text_field("#end_on");
  });
});
