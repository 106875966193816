$(document).on("turbolinks:load", function () {
  $(function () {
    $("li[id^=list_menue]").click(function () {
      $("li[id^=list_menue]").children(".panel-button__block").addClass("display-none");
      $(this).children(".panel-button__block").removeClass("display-none");
    });
  });
  $(document).on("click touchend", function (event) {
    // 表示したポップアップ以外の部分をクリックしたとき
    if (!$(event.target).closest("li[id^=list_menue]").length) {
      $(".panel-button__block").addClass("display-none");
    }
  });
});
