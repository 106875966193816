// 郵便番号入力時に、都道府県、市区町村を自動入力する
// 郵便番号入力フィールドをハイフンで区切っている場合もclass名を1,2で設定することで使用可能

// 本店所在地
$(document).on("turbolinks:load", function () {
  $(".get_ad_zipcode1").on("change", function () {
    get_address();
  });

  $(".get_ad_zipcode2").on("change", function () {
    get_address();
  });

  function get_address() {
    post_number1 = $(".get_ad_zipcode1").get(0).value;
    // post_number2が存在している時のみ、郵便番号の結合処理をおこなう
    post_number = $(".get_ad_zipcode2").length ? post_number1 + $(".get_ad_zipcode2").get(0).value : post_number1;

    if (post_number.match(/^[0-9]{7}$/)) {
      $.getJSON("/api/v1/get_addresses", "post_number=" + post_number, (json) => {
        if (json.pref_cd != "") {
          $(".set_ad_prefecture_code").get(0).value = json.pref_cd;
          $(".set_ad_address1").get(0).value = json.address;
        }
      });
    }
  }
});

// 連絡先住所
$(document).on("turbolinks:load", function () {
  $("#user_business_group_form_contact_zipcode1").on("change", function () {
    get_address();
  });

  $("#user_business_group_form_contact_zipcode2").on("change", function () {
    get_address();
  });

  function get_address() {
    post_number1 = $("#user_business_group_form_contact_zipcode1").get(0).value;
    // post_number2が存在している時のみ、郵便番号の結合処理をおこなう
    post_number = $("#user_business_group_form_contact_zipcode2").length
      ? post_number1 + $("#user_business_group_form_contact_zipcode2").get(0).value
      : post_number1;

    if (post_number.match(/^[0-9]{7}$/)) {
      $.getJSON("/api/v1/get_addresses", "post_number=" + post_number, (json) => {
        if (json.pref_cd != "") {
          $("#user_business_group_form_contact_prefecture_code").get(0).value = json.pref_cd;
          $("#user_business_group_form_contact_address1").get(0).value = json.address;
        }
      });
    }
  }
});

// 会計事務所所在地
$(document).on("turbolinks:load", function () {
  $(".get_ad_accountant_post1").on("change", function () {
    get_address();
  });

  $(".get_ad_accountant_post2").on("change", function () {
    get_address();
  });

  function get_address() {
    post_number1 = $(".get_ad_accountant_post1").get(0).value;
    // post_number2が存在している時のみ、郵便番号の結合処理をおこなう
    post_number = $(".get_ad_accountant_post2").length
      ? post_number1 + $(".get_ad_accountant_post2").get(0).value
      : post_number1;

    if (post_number.match(/^[0-9]{7}$/)) {
      $.getJSON("/api/v1/get_addresses", "post_number=" + post_number, (json) => {
        if (json.pref_cd != "") {
          $(".set_ad_accountant_prefecture_code").get(0).value = json.pref_cd;
          $(".set_ad_accountant_address1").get(0).value = json.address;
        }
      });
    }
  }
});
