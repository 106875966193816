////  チェック全選択・解除

$(document).on("turbolinks:load", function () {
  $(function () {
    // 「全選択」する
    $("#notice_all_check").on("click", function () {
      $("input[name^='noticelist']").prop("checked", this.checked);
    });

    // 「全選択」以外のチェックボックスがクリックされたら、
    $("input[name^='noticelist']").on("click", function () {
      if ($(".notice-list :checked").length == $(".notice-list :input").length) {
        // 全てのチェックボックスにチェックが入っていたら、「全選択」 = checked
        $("#notice_all_check").prop("checked", true);
      } else {
        // 1つでもチェックが入っていたら、「全選択」 = checked
        $("#notice_all_check").prop("checked", false);
      }
    });
  });
});
