document.addEventListener("turbolinks:load", function () {
  // ページネーションのボタンを表示（共通処理）
  function display_paginator(paginator_html) {
    $(".pagination__box").children().remove();
    $(".pagination__box").append(paginator_html);
  }

  // 交渉案件一覧を表示（共通処理）
  function display_ma_projects_page(ma_projects_page_html) {
    $(".js-ma_projects").remove();
    if (ma_projects_page_html !== "") {
      $(".result-control--upper").after(ma_projects_page_html);
      $(".result-control").show();
    }
  }

  // ページネーション
  $(function () {
    $("div.pagination.pagination-project").on("ajax:success", function (data) {
      console.log(data); // dataを確認する
      display_paginator(data.detail[0]["paginator"]);
      display_ma_projects_page(data.detail[0]["ma_projects_page"]);
      $("p.result-control__num").html(data.detail[0]["page_display"]);
    });
  });

  $(function () {
    // 非同期でsubmitして結果を表示（共通処理）
    $(".js-buyer-archive-hidden-checkbox").change(function () {
      $.ajax({
        type: "GET",
        url: "/buyer/mypage/ma_projects",
        data: {
          show_archive: $("#show_archive")[0].checked
        },
        dataType: "json"
      })
        .done(function (data) {
          console.log(data); // dataを確認する
          history.replaceState("", "", `?show_archive=${$("#show_archive")[0].checked}`);
          display_paginator(data.paginator);
          display_ma_projects_page(data.ma_projects_page);
          $("p.result-control__num").html(data.page_display);
        })
        .fail(function (data) {
          console.log(data); // dataを確認する
          alert("非同期通信に失敗しました");
        });
    });
  });
});
