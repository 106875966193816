$(document).on("turbolinks:load", function () {
  // アクター選択画面でのパーシャル切り替え
  $(function () {
    $(".purpose-list__box-inner--seller").on("click", function () {
      $(".display-block").removeClass("display-block");
      $(".detail-contact").removeClass("display-none");

      if ($(".seller_flow button:first").hasClass("button-secondary")) {
        $(".registration-tab").toggleClass("button-primary button-secondary");
      }
      $(".seller_flow").addClass("display-block");
      $(".seller_flow_regular").addClass("display-block");
      $(".regular_registration").addClass("display-block");

      $(".modal-title").text("売手で登録します。よろしいですか？");

      // アクター選択時の表示切り替え
      $(".purpose-list__box-inner--bayer--active").removeClass("purpose-list__box-inner--bayer--active");
      $(".purpose-list__box-inner--ma--active").removeClass("purpose-list__box-inner--ma--active");
      $(".purpose-list__box-inner--buyerfa--active").removeClass("purpose-list__box-inner--buyerfa--active");
      $(this).addClass("purpose-list__box-inner--seller--active");

      $(this).find("input").prop("checked", true);
    });

    $(".purpose-list__box-inner--bayer").on("click", function () {
      $(".display-block").removeClass("display-block");
      $(".detail-contact").removeClass("display-none");

      $(".buyer_flow").addClass("display-block");
      $(".registration").addClass("display-block");

      $(".modal-title").text("買手で登録します。よろしいですか？");

      // アクター選択時の表示切り替え
      $(".purpose-list__box-inner--seller--active").removeClass("purpose-list__box-inner--seller--active");
      $(".purpose-list__box-inner--ma--active").removeClass("purpose-list__box-inner--ma--active");
      $(".purpose-list__box-inner--buyerfa--active").removeClass("purpose-list__box-inner--buyerfa--active");
      $(this).addClass("purpose-list__box-inner--bayer--active");

      $(this).find("input").prop("checked", true);
    });

    $(".purpose-list__box-inner--ma").on("click", function () {
      $(".display-block").removeClass("display-block");
      $(".detail-contact").removeClass("display-none");

      $(".pap_flow").addClass("display-block");
      $(".registration").addClass("display-block");

      $(".modal-title").text("弥生PAPで登録します。よろしいですか？");

      // アクター選択時の表示切り替え
      $(".purpose-list__box-inner--seller--active").removeClass("purpose-list__box-inner--seller--active");
      $(".purpose-list__box-inner--bayer--active").removeClass("purpose-list__box-inner--bayer--active");
      $(".purpose-list__box-inner--buyerfa--active").removeClass("purpose-list__box-inner--buyerfa--active");
      $(this).addClass("purpose-list__box-inner--ma--active");

      $(this).find("input").prop("checked", true);
    });

    $(".purpose-list__box-inner--buyerfa").on("click", function () {
      $(".display-block").removeClass("display-block");
      $(".detail-contact").removeClass("display-none");

      $(".buyer_fa_mode_flow").addClass("display-block");
      $(".registration").addClass("display-block");

      $(".modal-title").text("買手FAで登録します。よろしいですか？");

      // アクター選択時の表示切り替え
      $(".purpose-list__box-inner--seller--active").removeClass("purpose-list__box-inner--seller--active");
      $(".purpose-list__box-inner--bayer--active").removeClass("purpose-list__box-inner--bayer--active");
      $(".purpose-list__box-inner--ma--active").removeClass("purpose-list__box-inner--ma--active");
      $(this).addClass("purpose-list__box-inner--buyerfa--active");

      $(this).find("input").prop("checked", true);
    });

    $(".registration-tab").click(function () {
      $(".display-block").removeClass("display-block");
      if ($(this).hasClass("button-secondary")) {
        $(".registration-tab").toggleClass("button-primary button-secondary");
      }
      const index = $(".registration-tab").index($(this));
      switch (index) {
        case 0:
          $(".seller_flow").addClass("display-block");
          $(".seller_flow_regular").addClass("display-block");
          $(".detail-contact").removeClass("display-none");
          $(".regular_registration").addClass("display-block");
          break;
        case 1:
          $(".seller_flow").addClass("display-block");
          $(".seller_flow_proxy").addClass("display-block");
          $(".detail-contact").addClass("display-none");
          break;
        case 2:
          $(".buyer_fa_reg_type").addClass("display-block");
          $(".buyer_fa_mode_flow").addClass("display-block");
          $(".detail-contact").removeClass("display-none");
          $(".registration").addClass("display-block");
          break;
        case 3:
          $(".buyer_fa_reg_type").addClass("display-block");
          $(".buyer_fa_flow").addClass("display-block");
          $(".detail-contact").removeClass("display-none");
          $(".registration").addClass("display-block");
          break;
      }
    });
  });

  // リンク変更防止
  $(function () {
    $(".js-nav-location-pap").on("click", function () {
      const url = "https://www.yayoi-kk.co.jp/pap/index.html";
      window.open(url, "_blank");
    });
  });

  // モーダル非表示（利用規約同意画面にて、PAPのmyportal_bns_grp_idのアラートモーダルに使用）
  $("#pap-alert-modal-close").on("click", () => $(".pap-alert-modal").fadeOut());

  // 利用登録の利用規約同意画面のボタン制御用
  $(function () {
    $("input[type='checkbox']").change(function () {
      if (
        $("#terms_01").prop("checked") &&
        $("#terms_02").prop("checked") &&
        $("#terms_03").prop("checked") &&
        $("#terms_04").prop("checked")
      ) {
        $(".confirm_of_terms_button").prop("disabled", false);
      } else {
        $(".confirm_of_terms_button").prop("disabled", true);
      }
    });
  });

  $(document).on("change", "#user_business_group_form_business_form_0", function (e) {
    if ($(this).prop("checked")) {
      // 法人→個人の変更時に個人情報を会社情報にコピー＆個人に不要な情報をクリア
      const lastName = $("#user_business_group_form_last_name").val();
      const firstName = $("#user_business_group_form_first_name").val();
      const lastNamekana = $("#user_business_group_form_last_name_kana").val();
      const firstNamekana = $("#user_business_group_form_first_name_kana").val();
      const birthday = $("#user_business_group_form_representative_birthday").val();
      const tel = $("#user_business_group_form_tel").val();
      $("#user_business_group_form_corporate_num").val("");
      $("#user_business_group_form_name").val(lastName + firstName);
      $("#user_business_group_form_representative_last_name").val(lastName);
      $("#user_business_group_form_representative_first_name").val(firstName);
      $("#user_business_group_form_name_kana").val(lastNamekana + firstNamekana);
      $("#user_business_group_form_representative_last_name_kana").val(lastNamekana);
      $("#user_business_group_form_representative_first_name_kana").val(firstNamekana);
      $("#user_business_group_form_representative_birthday").val(birthday);
      $("#user_business_group_form_representative_tel").val(tel);
      $(".contact__industry-list").remove();
      $("#user_business_group_form_url").val("");

      // 法人用の項目を非表示
      $(".corporate_num_form").hide();
      $(".industry_select_form").hide();
      $(".company_url_form").hide();
      $(".corporation_name").hide();
      $(".corporation_name_kana").hide();
      $(".corporation_rep_full_name").hide();
      $(".corporation_rep_full_name_kana").hide();
      $(".representative_tel_form").hide();

      // 個人になったときに表示文言を切り替える
      $(
        '<h4 class="contact__head information__title-label"> お客さま情報<input type="submit" name="temp_save" value="一時保存" class="button-action" data-disable-with="一時保存"></h4>'
      ).replaceAll(".information__title-label");
      $(
        '<label class="contact__title-label birthday__title-label"> 生年月日<span class="required_ast">＊</span></label>'
      ).replaceAll(".birthday__title-label");
      $('<dt class="contact__title address__title-label"> 住所<span class="required_ast">＊</span></dt>').replaceAll(
        ".address__title-label"
      );
    }
  });

  $(document).on("change", "#user_business_group_form_business_form_1", function (e) {
    if ($(this).prop("checked")) {
      // 個人→法人の切替時に個人に不要な情報をクリア
      $("#user_business_group_form_name").val("");
      $("#user_business_group_form_representative_last_name").val("");
      $("#user_business_group_form_representative_first_name").val("");
      $("#user_business_group_form_name_kana").val("");
      $("#user_business_group_form_representative_last_name_kana").val("");
      $("#user_business_group_form_representative_first_name_kana").val("");
      $("#user_business_group_form_representative_tel").val("");

      // 法人用の項目を表示
      $(".corporate_num_form").show();
      $(".representative_birthday_form").show();
      $(".industry_select_form").show();
      $(".company_url_form").show();
      $(".corporation_name").show();
      $(".corporation_name_kana").show();
      $(".corporation_rep_full_name").show();
      $(".corporation_rep_full_name_kana").show();
      $(".representative_tel_form").show();

      // 法人になったときに表示文言を切り替える
      $(
        '<h4 class="contact__head information__title-label"> 法人情報<input type="submit" name="temp_save" value="一時保存" class="button-action" data-disable-with="一時保存"></h4>'
      ).replaceAll(".information__title-label");
      $(
        '<label class="contact__title-label birthday__title-label"> 代表者生年月日<span class="required_ast">＊</span></label>'
      ).replaceAll(".birthday__title-label");
      $(
        '<dt class="contact__title address__title-label"> 本店所在地<span class="required_ast">＊</span></dt>'
      ).replaceAll(".address__title-label");
    }
  });

  // 証明書類1（個人）のドラッグアンドドロップ制御
  $(document).ready(function () {
    $("#drop-area-identification_indiv1").on("dragover", function (e) {
      e.preventDefault();
    });
    $("#drop-area-identification_indiv1").on("drop", function (e) {
      e.preventDefault();

      const files = e.originalEvent.dataTransfer.files;

      // アップロードされたファイルが1個でなければreturn
      if (files.length != 1) return;

      // 無効な拡張子の場合はreturn
      if (invalidFileExtension(files, ".(pdf|png|jpg)$")) return;

      document.getElementById("identification_document_file_indiv1").files = files;
      const file = $("#identification_document_file_indiv1").prop("files")[0];
      $("li.reg-dd__item.document_indiv1").remove();
      $("ul.reg-dd__list.document_indiv1").append(`<li class="reg-dd__item document_indiv1">${file.name}</li>`);
    });
  });

  // 証明書類2（個人）のドラッグアンドドロップ制御
  $(document).ready(function () {
    $("#drop-area-identification_indiv2").on("dragover", function (e) {
      e.preventDefault();
    });
    $("#drop-area-identification_indiv2").on("drop", function (e) {
      e.preventDefault();

      const files = e.originalEvent.dataTransfer.files;

      // アップロードされたファイルが1個でなければreturn
      if (files.length != 1) return;

      // 無効な拡張子の場合はreturn
      if (invalidFileExtension(files, ".(pdf|png|jpg)$")) return;

      document.getElementById("identification_document_file_indiv2").files = files;
      const file = $("#identification_document_file_indiv2").prop("files")[0];
      $("li.reg-dd__item.document_indiv2").remove();
      $("ul.reg-dd__list.document_indiv2").append(`<li class="reg-dd__item document_indiv2">${file.name}</li>`);
    });
  });

  // 履歴事項全部証明書のドラッグアンドドロップ制御
  $(document).ready(function () {
    $("#drop-area-identification1").on("dragover", function (e) {
      e.preventDefault();
    });
    $("#drop-area-identification1").on("drop", function (e) {
      e.preventDefault();

      const files = e.originalEvent.dataTransfer.files;

      // アップロードされたファイルが1個でなければreturn
      if (files.length != 1) return;

      // 無効な拡張子の場合はreturn
      if (invalidFileExtension(files, ".(pdf)$")) return;

      document.getElementById("identification_document_file1").files = files;
      const file = $("#identification_document_file1").prop("files")[0];
      $("li.reg-dd__item.document1").remove();
      $("ul.reg-dd__list.document1").append(`<li class="reg-dd__item document1">${file.name}</li>`);
    });
  });

  // 会社概要のドラッグアンドドロップ制御
  $(document).ready(function () {
    $("#drop-area-identification2").on("dragover", function (e) {
      e.preventDefault();
    });
    $("#drop-area-identification2").on("drop", function (e) {
      e.preventDefault();

      const files = e.originalEvent.dataTransfer.files;

      // アップロードされたファイルが1個でなければreturn
      if (files.length != 1) return;

      // 無効な拡張子の場合はreturn
      if (invalidFileExtension(files, ".(pdf)$")) return;

      document.getElementById("identification_document_file2").files = files;
      const file = $("#identification_document_file2").prop("files")[0];
      $("li.reg-dd__item.document2").remove();
      $("ul.reg-dd__list.document2").append(`<li class="reg-dd__item document2">${file.name}</li>`);
    });
  });

  function invalidFileExtension(files, allow_extensions) {
    // 許可している拡張子でなければtrue
    if (!files[0].name.match(allow_extensions)) return true;
  }

  // 証明書類1の選択ファイル表示制御用(個人)
  $(function () {
    let files;
    $("#identification_document_file_indiv1").on("click", function () {
      files = $(this).prop("files");
    });
    $("#identification_document_file_indiv1").change(function () {
      const file = $(this).prop("files");
      if (!file.length) {
        $(this)[0].files = files;
        return;
      }
      $(".reg-dd__item.document_indiv1").remove();
      $("ul.reg-dd__list.document_indiv1").append(`<li class="reg-dd__item document_indiv1">${file[0].name}</li>`);
    });
  });
  // 証明書類1の選択ファイルを消去する（個人）
  $(document).on("click", ".reg-dd__item.document_indiv1", function () {
    $(this).remove();
    $("input[type='file']").val("");
  });

  // 証明書類2の選択ファイル表示制御用(個人)
  $(function () {
    let files;
    $("#identification_document_file_indiv2").on("click", function () {
      files = $(this).prop("files");
    });
    $("#identification_document_file_indiv2").change(function () {
      const file = $(this).prop("files");
      if (!file.length) {
        $(this)[0].files = files;
        return;
      }
      $(".reg-dd__item.document_indiv2").remove();
      $("ul.reg-dd__list.document_indiv2").append(`<li class="reg-dd__item document_indiv2">${file[0].name}</li>`);
    });
  });
  // 証明書類1の選択ファイルを消去する（個人）
  $(document).on("click", ".reg-dd__item.document_indiv2", function () {
    $(this).remove();
    $("input[type='file']").val("");
  });

  // 履歴事項全部証明書の選択ファイル表示制御用(法人)
  $(function () {
    let files;
    $("#identification_document_file1").on("click", function () {
      files = $(this).prop("files");
    });
    $("#identification_document_file1").change(function () {
      const file1 = $(this).prop("files");
      if (!file1.length) {
        $(this)[0].files = files;
        return;
      }
      $(".reg-dd__item.document1").remove();
      $("ul.reg-dd__list.document1").append(`<li class="reg-dd__item document1">${file1[0].name}</li>`);
    });
  });
  // 履歴事項全部証明書の選択ファイルを消去する（法人）
  $(document).on("click", ".reg-dd__item.document1", function () {
    $(this).remove();
    $("#identification_document_file1").val("");
  });

  // 会社概要の選択ファイル表示制御用(法人)
  $(function () {
    let files;
    $("#identification_document_file2").on("click", function () {
      files = $(this).prop("files");
    });
    $("#identification_document_file2").change(function () {
      const file2 = $(this).prop("files");
      if (!file2.length) {
        $(this)[0].files = files;
        return;
      }
      $(".reg-dd__item.document2").remove();
      $("ul.reg-dd__list.document2").append(`<li class="reg-dd__item document2">${file2[0].name}</li>`);
    });
  });
  // 会社概要の選択ファイルを消去する（法人）
  $(document).on("click", ".reg-dd__item.document2", function () {
    $(this).remove();
    $("#identification_document_file2").val("");
  });

  //業種モーダルの選択を反映させる
  $(`button.button-primary#input-data-industry`).click(function () {
    selected_checks("industry");
  });
  //業種モーダルの選択を反映させる関数（処理詳細）
  function selected_checks(input_name) {
    // 業種モーダルで選択した項目を取得
    const input_values = document.querySelectorAll(`input[name=${input_name}]:checked`);
    const td_class = ".contact__industry";
    if (0 < input_values.length) {
      for (var data of input_values) {
        var data_id = parseInt(data.value.split(",")[0]);
        var data_name = data.value.split(",")[1];
        $(`${td_class} .contact__industry-list`).remove();
        sessionStorage.industry_id = `${data_id}`;
        sessionStorage.industry_name = `${data_name}`;
        $(td_class).prepend(
          `<ul class="contact__industry-list"><li class="contact__industry-tag"><p class="contact__industry-tag_link">
          <input type="checkbox" checked="true" value="${data_id}" id="${data_id}" name="user_business_group_form[industry_id]">${data_name}</p></li></ul>`
        );
      }
    }
    // 業種モーダルを閉じる
    $(`.select-${input_name}`).fadeOut();
  }
  // 選択済項目を消去(業種)
  $(document).on("click", ".contact__industry-tag", function () {
    // 項目削除
    $(`input[id="industry_${$(this).find("input")[0]["value"]}"]:checked`).prop("checked", false);
    $(`input[name="industry"]:checked`).prop("checked", false);
    $(this).remove();
  });

  // 選択済みの業種を業種モーダル内で初期表示させる
  const checkedIndustry = $("input[name=industry]:checked");
  if (checkedIndustry.length) {
    checkedIndustry.closest(".search-industry__item").addClass("is-active");
    checkedIndustry.closest(".search-industry__item").children(".search-industry__panel").show();
  }

  // 買手（個人）だった場合の会社名の自動入力制御
  $("#user_business_group_form_last_name, #user_business_group_form_first_name").on("input", function () {
    if (!$("#user_business_group_form_business_form_0").prop("checked")) return;

    var lastName = $("#user_business_group_form_last_name").val();
    var firstName = $("#user_business_group_form_first_name").val();
    $("#user_business_group_form_name").val(lastName + firstName);
    $("#user_business_group_form_representative_last_name").val(lastName);
    $("#user_business_group_form_representative_first_name").val(firstName);
  });
  // 買手（個人）だった場合の会社名の自動入力制御（カナ）
  $("#user_business_group_form_last_name_kana, #user_business_group_form_first_name_kana").on("input", function () {
    if (!$("#user_business_group_form_business_form_0").prop("checked")) return;

    var lastNamekana = $("#user_business_group_form_last_name_kana").val();
    var firstNamekana = $("#user_business_group_form_first_name_kana").val();
    $("#user_business_group_form_name_kana").val(lastNamekana + firstNamekana);
    $("#user_business_group_form_representative_last_name_kana").val(lastNamekana);
    $("#user_business_group_form_representative_first_name_kana").val(firstNamekana);
  });
  // 買手（個人）だった場合の代表電話番号の自動入力制御
  $("#user_business_group_form_tel").on("input", function () {
    if (!$("#user_business_group_form_business_form_0").prop("checked")) return;

    var tel = $("#user_business_group_form_tel").val();
    $("#user_business_group_form_representative_tel").val(tel);
  });
  // Ctrl+クリックを無効化
  $(".button-back").click(function (e) {
    if (e.ctrlKey || e.metaKey) {
      e.preventDefault();
    }
  });
});
