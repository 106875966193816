// 初期設定(日本語化)
let option = {
  format: "YYYY/MM/DD",
  applyLabel: "選択",
  cancelLabel: "クリア",
  fromLabel: "開始日",
  toLabel: "終了日",
  weekLabel: "W",
  customRangeLabel: "自分で指定",
  daysOfWeek: ["日", "月", "火", "水", "木", "金", "土"],
  monthNames: ["01月", "02月", "03月", "04月", "05月", "06月", "07月", "08月", "09月", "10月", "11月", "12月"]
};

$(document).on("turbolinks:load", function () {
  $(".datepicker-range").daterangepicker(
    {
      autoApply: true,
      autoUpdateInput: false,
      locale: option
    },
    function (start, end, label) {
      $(this.element).val(start.format("YYYY/MM/DD") + " - " + end.format("YYYY/MM/DD"));
    }
  );

  $(".datepicker-single").daterangepicker(
    {
      autoApply: true,
      timePicker: false,
      singleDatePicker: true,
      locale: option
    },
    function (day) {
      $(this.element).val(day.format("YYYY/MM/DD"));
    }
  );

  $(".datetimepicker-range").daterangepicker(
    {
      autoApply: true,
      timePicker: true,
      timePicker24Hour: true,
      timePickerSeconds: true,
      autoUpdateInput: false,
      locale: set_locale_option({
        format: "YYYY/MM/DD HH:mm:ss"
      })
    },
    function (start, end, label) {
      $(this.element)
        .val(start.format("YYYY/MM/DD HH:mm:ss") + " - " + end.format("YYYY/MM/DD HH:mm:ss"))
        .change();
    }
  );

  $(".datetimepicker-single").daterangepicker(
    {
      autoApply: true,
      setDate: null,
      timePicker: true,
      timePicker24Hour: true,
      timePickerSeconds: true,
      singleDatePicker: true,
      locale: set_locale_option({
        format: "YYYY/MM/DD HH:mm:ss"
      })
    },
    function (day) {
      $(this.element).val(day.format("YYYY/MM/DD HH:mm:ss")).change();
    }
  );
  //初期表示ブランクのシングルカレンダー
  $(".datetimepicker-single-blank").daterangepicker(
    {
      autoApply: true,
      autoUpdateInput: false,
      setDate: null,
      timePicker: true,
      timePicker24Hour: true,
      timePickerSeconds: true,
      singleDatePicker: true,
      locale: set_locale_option({
        format: "YYYY/MM/DD HH:mm:ss"
      })
    },
    function (day) {
      $(this.element).val(day.format("YYYY/MM/DD HH:mm:ss")).change();
    }
  );
  //お知らせのカレンダー、秒設定なし
  $(".datetimepicker-information").daterangepicker({
    autoApply: true,
    autoUpdateInput: false,
    setDate: null,
    timePicker: true,
    timePicker24Hour: true,
    singleDatePicker: true,
    locale: set_locale_option({
      format: "YYYY/MM/DD HH:mm:ss",
      cancelLabel: "Clear"
    })
  });

  $(".datetimepicker-information").on("apply.daterangepicker", function (ev, day) {
    $(this).val(day.startDate.format("YYYY/MM/DD HH:mm")).change();
  });

  $(".datetimepicker-information").on("cancel.daterangepicker", function () {
    $(this).val("");
  });

  // 誕生日用、15歳未満の日付は選択できない
  $(".datepicker-birthday").daterangepicker(
    {
      autoApply: true,
      timePicker: false,
      singleDatePicker: true,
      showDropdowns: true,
      startDate: "1950/01/01",
      maxDate: years_before(15),
      autoUpdateInput: false,
      locale: option
    },
    function (day) {
      $(this.element).val(day.format("YYYY/MM/DD")).change();
    }
  );
  // 当日以降３年以内の日付のみ選択可（あんしんエージェント登録期限用）
  $(".datepicker-end-on").daterangepicker({
    autoApply: true,
    timePicker: false,
    singleDatePicker: true,
    showDropdowns: true,
    startDate: months_later(3),
    minDate: days_later(1),
    maxDate: years_later(3),
    locale: set_locale_option({
      format: "YYYY/MM/DD"
    })
  });
  // 翌日以降〜１年後のみ指定可能&nullを許容（他は基本３年後まで）
  $(".datepicker-dd-on").daterangepicker(
    {
      autoApply: true,
      timePicker: false,
      singleDatePicker: true,
      minDate: days_later(1),
      maxDate: years_later(1),
      autoUpdateInput: false,
      locale: option
    },
    function (day) {
      $(this.element).val(day.format("YYYY/MM/DD")).change();
    }
  );
  $(document).on("focusout", ".datepicker-dd-on", function () {
    if ($(this).val() == "") {
      return;
    }
    const inputDate = new Date(new Date($(this).val()).setHours(0, 0, 0, 0));
    if (Number.isNaN(inputDate.getTime())) {
      $(this).val("");
      return;
    }
    let minDate = new Date(new Date().setHours(0, 0, 0, 0));
    let maxDate = new Date(new Date().setHours(0, 0, 0, 0));
    minDate = new Date(minDate.setDate(minDate.getDate() + 1));
    maxDate = new Date(maxDate.setFullYear(maxDate.getFullYear() + 1));
    if (inputDate < minDate) {
      $(this).val(`${minDate.getFullYear()}/${minDate.getMonth() + 1}/${minDate.getDate()}`);
    } else if (inputDate > maxDate) {
      $(this).val(`${maxDate.getFullYear()}/${maxDate.getMonth() + 1}/${maxDate.getDate()}`);
    } else {
      $(this).val(`${inputDate.getFullYear()}/${inputDate.getMonth() + 1}/${inputDate.getDate()}`);
    }
  });

  // 翌日以降のみ指定可能&nullを許容
  $(".datepicker-period-on").daterangepicker(
    {
      autoApply: true,
      timePicker: false,
      singleDatePicker: true,
      minDate: days_later(1),
      maxDate: years_later(3),
      autoUpdateInput: false,
      locale: option
    },
    function (day) {
      $(this.element).val(day.format("YYYY/MM/DD")).change();
    }
  );
  $(document).on("focusout", ".datepicker-period-on", function () {
    if ($(this).val() == "") {
      return;
    }
    const inputDate = new Date(new Date($(this).val()).setHours(0, 0, 0, 0));
    if (Number.isNaN(inputDate.getTime())) {
      $(this).val("");
      return;
    }
    let minDate = new Date(new Date().setHours(0, 0, 0, 0));
    let maxDate = new Date(new Date().setHours(0, 0, 0, 0));
    minDate = new Date(minDate.setDate(minDate.getDate() + 1));
    maxDate = new Date(maxDate.setFullYear(maxDate.getFullYear() + 3));
    if (inputDate < minDate) {
      $(this).val(`${minDate.getFullYear()}/${minDate.getMonth() + 1}/${minDate.getDate()}`);
    } else if (inputDate > maxDate) {
      $(this).val(`${maxDate.getFullYear()}/${maxDate.getMonth() + 1}/${maxDate.getDate()}`);
    } else {
      $(this).val(`${inputDate.getFullYear()}/${inputDate.getMonth() + 1}/${inputDate.getDate()}`);
    }
  });

  // 翌日以降の日付のみ選択可
  $(".datepicker-next-day").daterangepicker(
    {
      autoApply: true,
      timePicker: false,
      singleDatePicker: true,
      minDate: days_later(1),
      maxDate: years_later(3),
      startDate: months_later(3),
      locale: option
    },
    function (day) {
      $(this.element).val(day.format("YYYY/MM/DD"));
    }
  );
  // 7日後以降のみ指定可能&nullを許容
  $(".datepicker-reply-on").daterangepicker(
    {
      autoApply: true,
      timePicker: false,
      singleDatePicker: true,
      minDate: days_later(7),
      maxDate: years_later(3),
      autoUpdateInput: false,
      locale: option
    },
    function (day) {
      $(this.element).val(day.format("YYYY/MM/DD")).change();
    }
  );
  // 30日後以降のみ指定可能
  $(".datepicker-publish-status").daterangepicker(
    {
      autoApply: true,
      timePicker: false,
      singleDatePicker: true,
      minDate: days_later(30),
      maxDate: years_later(3),
      locale: option
    },
    function (day) {
      $(this.element).val(day.format("YYYY/MM/DD")).change();
    }
  );
  // localオプションを追加したい場合は使用
  function set_locale_option(hash) {
    for (key in hash) {
      option[key] = hash[key];
    }
    return option;
  }
  // 入力フォームで日付の入力のみ(半角数字及び半角スラッシュのみ)許可する要素に.only_dateクラスを追加
  $(".only_date").on("input change", function () {
    let value = $(this).val();
    value = value
      .replace(/[０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      })
      .replace(/[^\d\/]/g, "");
    $(this).val(value);
  });
});

// {引数の値}日後の日付データを返す
function days_later(span) {
  var date = new Date();
  date.setDate(date.getDate() + span);
  return date;
}

// {引数の値}ヶ月後の日付データを返す
function months_later(span) {
  var date = new Date();
  date.setMonth(date.getMonth() + span);
  return date;
}

// {引数の値}年後の日付データを返す
function years_later(span) {
  var date = new Date();
  date.setFullYear(date.getFullYear() + span);
  return date;
}

// {引数の値}年前の日付データを返す
function years_before(span) {
  var date = new Date();
  date.setFullYear(date.getFullYear() - span);
  return date;
}
