$(document).on("turbolinks:load", function () {
  $(function () {
    // 登録期間の自動更新ラジオボタン
    $(".auto_updating_flag").on("change", function () {
      if ($(this).val() == "true") {
        $("#end_on").attr("disabled", true);
        $("#end_on").addClass("is-disabled");
      } else {
        $("#end_on").attr("disabled", false);
        $("#end_on").removeClass("is-disabled");
      }
    });
  });
});
